<template>
  <v-container>
    <v-row no-gutters class="mb-4">
      <v-col cols="12" class="text-center white--text">
        <p>For Nina Kosaka. From your honeys.</p>
        <p>
          Management: Ninacord Staff<br />
          Programming: jetrico<br />
          Header, Background, Card Design: Professor Nobu<br />
          Hand assets: @dEi_pft<br />
          God Sees All (kazoo cover): Altair<br />
          Drawing Board: jetrico, Professor Nobu, NobleNoisii, KAO,
            AngelCokeLife, Altair, Clover, Komet keiko, Voldox,
            Trey, Tabitha, Premierner, Cerise, TaCrimson
        </p>
        <p>
          and all honeys who celebrate these milestones with Nina and Ethyria!
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: [],
  data: () => ({
    //
  }),
};
</script>

<style lang="scss" scoped>

</style>
